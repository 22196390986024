import emailjs from "@emailjs/browser";

const toNaver = () => {
  window.location.href = `https://new-m.pay.naver.com/remit/qr/inflow?v=1&a=1002133822329&c=020&d=ad7f45ed74d9ad4643a9e991de151956`;
};
const toPaypal = () => {
  window.location.href = "https://www.paypal.com/ncp/payment/LLAG6R72VHCLS";
};
const toPaypalSub = () => {
  window.location.href =
    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5KS63123TG395143UM2Y2X7A";
};

const toKakao = (reqPeriod) => {
  switch (reqPeriod) {
    case 1:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz138801645`;
      break;
    case 2:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz271002714`;
      break;
    case 3:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz3a9801670`;
      break;
    case 4:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz4e2005886`;
      break;
    case 5:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz61a805244`;
      break;
    case 6:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz753005971`;
      break;
    case 12:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNzc35003851`;
      break;

    default:
      window.location.href = `https://qr.kakaopay.com/Ej7sKGVNz138801645`;
      break;
  }
};

export const sendJoinEmail = (
  name,
  nickname,
  insta,
  q1Ans,
  q2Ans,
  q3Ans,
  referee
) => {
  emailjs
    .send(
      "service_ynqt3v5",
      "template_q0etdx6",
      {
        name,
        nickname,
        insta,
        q1Ans,
        q2Ans,
        q3Ans,
        referee,
      },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(
      () => console.log("ok 200"),
      (err) => console.log(err)
    );
};
export const sendPayEmail = (nickname, reqPeriod, platform) => {
  const money = reqPeriod === 12 ? 100000 : reqPeriod * 10000;
  const fulltime = new Date();
  const yy = `${fulltime.getFullYear()}`;
  const mm = `${
    fulltime.getMonth() + 1 > 10
      ? (fulltime.getMonth() + 1).toString()
      : "0" + (fulltime.getMonth() + 1).toString()
  }`;
  const dd = `${
    fulltime.getDate() > 10
      ? fulltime.getDate().toString()
      : "0" + fulltime.getDate().toString()
  }`;
  const hh = `${
    fulltime.getHours() > 10
      ? fulltime.getHours().toString()
      : "0" + fulltime.getHours().toString()
  }`;
  const MM = `${
    fulltime.getMinutes() > 10
      ? fulltime.getMinutes().toString()
      : "0" + fulltime.getMinutes().toString()
  }`;
  const ss = `${
    fulltime.getSeconds() > 10
      ? fulltime.getSeconds().toString()
      : "0" + fulltime.getSeconds().toString()
  }`;

  emailjs
    .send(
      "service_ynqt3v5",
      "template_q0etdx6",
      {
        nickname,
        money,
        time: `${yy}-${mm}-${dd}T${hh}:${MM}:${ss}`,
        platform,
      },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(
      () => {
        console.log("ok 200");
        switch (platform) {
          case "네이버":
            toNaver();
            break;
          case "카카오":
            toKakao(reqPeriod);
            break;
          case "페이팔":
            toPaypal();
            break;
          case "페이팔 구독":
            toPaypalSub();
            break;
          default:
            break;
        }
      },
      (err) => console.log(err)
    );
};

export const sendBoogimarket = (nickname, title, reason) => {
  emailjs
    .send(
      "service_ynqt3v5",
      "template_n9u5wfe",
      { nickname, title, reason },
      "sDbK-2vvlIKrTJtdY"
    )
    .then(() => (window.location.href = "/"));
};
export const expired = (month) => {
  const curMonth = new Date().getMonth() + 1;
  return parseInt(month) < curMonth;
};
