import React, { useEffect } from "react";
import { Container } from "../Components/Layout";
import { Info, HorizontalBox, VerticalBox } from "../Components/Text";
import HeaderTitle from "../Components/Title";
import GAHoc from "../GA";
import Logo from "../Components/Logo";
import bookData from "../Data/books.json";
import MonthlyList from "../Components/MonthlyList";

const BooklistAll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="부기의 책장" />
        <HorizontalBox style={{ justifyContent: "flex-start" }}>
          <Info>부기의 책장</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
        <VerticalBox>
          {Object.values(bookData).map((book_of_month) => (
            <MonthlyList book_of_month={book_of_month} key={book_of_month.kr} />
          ))}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default BooklistAll;
