import React, { useEffect } from "react";
import { Container, Divider } from "../Components/Layout";
import { Info, VerticalBox } from "../Components/Text";
import HeaderTitle from "../Components/Title";
import GAHoc from "../GA";
import userData from "../Data/member.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Book from "../Components/Book";
import UserCard from "../Components/UserCard";

const BooklistUser = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { name } = useParams();

  const userBooks = userData[name].booklist;
  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${name}의 책장 | 부기북스`} />
        <VerticalBox>
          <Info>{`${name}의 책장`}</Info>
          <UserCard user={userData[name]} />
          {Object.values(userBooks).map((item) => (
            <>
              <Book bookInfo={item} key={item.title} />
              <Divider />
            </>
          ))}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default BooklistUser;
