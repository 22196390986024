import React, { useEffect, useState } from "react";
import { FormBtn, LinkBtn } from "../../Components/Button";
import { Container } from "../../Components/Layout";
import ProgressBar from "../../Components/ProgressBar";
import {
  HorizontalBox,
  P,
  PGray,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import { sendJoinEmail } from "../../Components/Utils";

const StateResponder = ({
  state,
  nickname,
  setNickname,
  name,
  setName,
  insta,
  setInsta,
  setDispState,
  q1Ans,
  setQ1Ans,
  q2Ans,
  setQ2Ans,
  setQ3Ans,
  setReferee,
  sendRes,
}) => {
  const selector = ({ target }) => {
    target.style.backgroundColor = "#85a977";
    let pre = target.previousSibling;
    let next = target.nextSibling;
    while (pre || next) {
      if (pre) {
        pre.style.backgroundColor = "";
        pre = pre.previousSibling;
      }
      if (next) {
        next.style.backgroundColor = "";
        next = next.nextSibling;
      }
    }
  };

  switch (state) {
    case "intro":
      return (
        <VerticalBox>
          <SectionTitle>{"환영합니다 !"}</SectionTitle>
        </VerticalBox>
      );
    case "name":
      return (
        <VerticalBox>
          <SectionTitle>{"개인정보 제공"}</SectionTitle>
          <Pre>{"입주자의 개인 정보를 수집합니다."}</Pre>
          <PGray>
            {
              "정보 수집은 신규 입주자의 독서모임 가입을 돕기 위함이며, 닉네임을 제외한 이름과 인스타그램 정보는 가입 즉시 폐기됩니다."
            }
          </PGray>
          <VerticalBox style={{ alignItems: "start" }}>
            <TextInput
              id="data1"
              key="nickname_input_value"
              style={{
                width: "100%",
                textAlign: "start",
                padding: "10px 15px",
              }}
              type="text"
              placeholder={"독서모임에서 사용할 닉네임을 알려주세요"}
              onChange={(e) => {
                setNickname(e.target.value);
              }}
              value={nickname}
            />
            {nickname ? (
              <TextInput
                id="data2"
                key="name_input_value"
                style={{
                  width: "100%",
                  textAlign: "start",
                  padding: "10px 15px",
                }}
                type="text"
                placeholder={"이름"}
                onChange={(e) => setName(e.target.value)}
              />
            ) : null}
            {name ? (
              <TextInput
                style={{
                  width: "100%",
                  textAlign: "start",
                  padding: "10px 15px",
                }}
                type="text"
                placeholder={"인스타그램 아이디"}
                onChange={(e) => setInsta(e.target.value)}
              />
            ) : null}
            {insta ? (
              <>
                <PGray>
                  {`지인 추천을 통해 오신 경우 대면모임 비용 할인 혜택을 제공해드립니다.`}
                </PGray>
                <TextInput
                  style={{
                    width: "100%",
                    textAlign: "start",
                    padding: "10px 15px",
                  }}
                  type="text"
                  placeholder={"추천인 닉네임"}
                  onChange={(e) => setReferee(e.target.value)}
                />
                <FormBtn
                  style={{
                    alignSelf: "end",
                    width: " 40%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    if (name === "") {
                      alert("닉네임이 제공되지 않았습니다.");
                      return;
                    } else if (nickname === "test") {
                      setDispState("pay");
                      return;
                    } else if (name === "") {
                      alert("이름이 제공되지 않았습니다.");
                      return;
                    } else if (insta === "") {
                      alert(
                        "인스타그램 ID가 제공되지 않았습니다. 독서모임 가입에 문제가 발생할 경우 관리자가 도움을 드릴 수 없습니다."
                      );
                      return;
                    }
                    setDispState("Q1");
                  }}
                >
                  제출
                </FormBtn>
              </>
            ) : null}
          </VerticalBox>
        </VerticalBox>
      );
    case "Q1":
      return (
        <>
          <VerticalBox>
            <SectionTitle>{"취향"}</SectionTitle>
            <Pre>{`${nickname} 님이 가장 좋아하는 활동은 어떤 것인가요?`}</Pre>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              옷, 가구, 소품 쇼핑 등 수집 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              영화 감상, 음악 감상 등 수동적 체험
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              전시 관람, 스포츠 경기 관람 등 능동적 체험
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              모임, 단체 운동 경기 등 외부 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              요리, 원데이클래스 등 공예 활동
            </FormBtn>
            <FormBtn
              onClick={(e) => {
                selector(e);
                setQ1Ans(e.target.textContent);
              }}
            >
              글쓰기, 그림그리기 등 창작 활동
            </FormBtn>
          </VerticalBox>
          {q1Ans ? (
            <VerticalBox>
              <SectionTitle>{"표현"}</SectionTitle>
              <Pre>
                {`${nickname} 님이 스스로를 표현할 때 가장 편하게 사용하는 방식은 무엇인가요?`}
              </Pre>
              <FormBtn
                onClick={(e) => {
                  selector(e);
                  setQ2Ans(e.target.textContent);
                }}
              >
                사진, 영상 등의 시각 자료
              </FormBtn>
              <FormBtn
                onClick={(e) => {
                  selector(e);
                  setQ2Ans(e.target.textContent);
                }}
              >
                묘사를 담은 글
              </FormBtn>
              <FormBtn
                onClick={(e) => {
                  selector(e);
                  setQ2Ans(e.target.textContent);
                }}
              >
                상대를 마주하고 전하는 말
              </FormBtn>
              <FormBtn
                onClick={(e) => {
                  selector(e);
                  setQ2Ans(e.target.textContent);
                }}
              >
                숫자를 포함해 정리된 데이터
              </FormBtn>
            </VerticalBox>
          ) : null}
          {q2Ans ? (
            <VerticalBox>
              <SectionTitle>{"호기심"}</SectionTitle>
              <Pre>
                {`${nickname} 님이 근래 가장 많이 찾아보고 궁금해하는 것은 무엇인가요?`}
              </Pre>
              <HorizontalBox>
                <TextInput
                  type="text"
                  placeholder={"최근의 호기심"}
                  onChange={(e) => setQ3Ans(e.target.value)}
                />
                <FormBtn
                  onClick={() => {
                    sendRes();
                    setDispState("toDiscord");
                  }}
                >
                  제출
                </FormBtn>
              </HorizontalBox>
            </VerticalBox>
          ) : null}
        </>
      );
    case "toDiscord":
      return (
        <VerticalBox>
          <SectionTitle>{"쉘하우스로 이동"}</SectionTitle>
          <P>1. 우선 아래 링크를 이용해 디스코드 앱을 설치해주세요.</P>
          <PGray>
            저희 독서모임은 디스코드 앱을 기반으로 한 서버에서 운영되고
            있습니다. 따라서 디스코드를 사용하지 않는 경우 독서모임 이용이
            어렵습니다.
          </PGray>
          <VerticalBox>
            <LinkBtn
              onClick={() => {
                window.open(
                  "https://discordapp.page.link/?link=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fdiscord-chat-for-games%2Fid985746746%3FattemptId%3Dc2499ffd-738d-4da3-9b18-7a36646e324a&utm_source=download&apn=com.discord&isi=985746746&ibi=com.hammerandchisel.discord&sd=Your%20place%20to%20talk%20with%20communities%20and%20friends.&efr=1"
                );
              }}
            >
              앱스토어로 이동
            </LinkBtn>
            <LinkBtn
              onClick={() => {
                window.open(
                  "https://discordapp.page.link/?link=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.discord%26attemptId%3De2530904-f3df-4aef-8a58-8db153c756fb&utm_source=download&apn=com.discord&isi=985746746&ibi=com.hammerandchisel.discord&sd=Your%20place%20to%20talk%20with%20communities%20and%20friends.&efr=1"
                );
              }}
            >
              플레이스토어로 이동
            </LinkBtn>
            <LinkBtn
              onClick={() => {
                window.open("https://discord.com/download");
              }}
            >
              데스크탑용 디스코드
            </LinkBtn>
          </VerticalBox>
          <P>
            2. 앱에서 회원가입과 로그인을 완료하고 아래 링크를 이용해 쉘하우스
            서버에 접속해주세요.
          </P>
          <LinkBtn
            onClick={() => {
              window.open("https://discord.gg/DaWAkgA4nc");
            }}
          >
            쉘하우스 서버 입장
          </LinkBtn>
          <PGray>
            만약 이미 디스코드를 사용하고 계신다면 바로 위의 초대링크를 통해
            쉘하우스로 입장해주세요.
          </PGray>
        </VerticalBox>
      );
    default:
      setDispState("name");
      return <VerticalBox>default</VerticalBox>;
  }
};

const Join = () => {
  const [dispState, setDispState] = useState("intro");
  // const [dispState, setDispState] = useState("toDiscord");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [insta, setInsta] = useState("");
  const [q1Ans, setQ1Ans] = useState("");
  const [q2Ans, setQ2Ans] = useState("");
  const [q3Ans, setQ3Ans] = useState("");
  const [referee, setReferee] = useState("");
  const [progress, setProgress] = useState(0);
  const progressUpdate = (num) => {
    if (num < 1.0005) setProgress(num);
  };
  const sendRes = () =>
    sendJoinEmail(name, nickname, insta, q1Ans, q2Ans, q3Ans, referee);

  useEffect(() => {
    const greeting = () => {
      const greetingTimerId = setTimeout(() => {
        progressUpdate(progress + 1 / 1000);

        if (progress >= 1) {
          setProgress(0);
          clearTimeout(greetingTimerId);
          setDispState("name");
        }
      }, 1);
      return greetingTimerId;
    };
    if (dispState === "intro") {
      greeting();
      return () => clearTimeout(greeting());
    }
  }, [dispState, progress]);

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="입주 | 쉘하우스" />
        <StateResponder
          state={dispState}
          nickname={nickname}
          setNickname={setNickname}
          name={name}
          setName={setName}
          insta={insta}
          setInsta={setInsta}
          setDispState={setDispState}
          q1Ans={q1Ans}
          setQ1Ans={setQ1Ans}
          q2Ans={q2Ans}
          setQ2Ans={setQ2Ans}
          setQ3Ans={setQ3Ans}
          setReferee={setReferee}
          sendRes={sendRes}
        />
      </Container>
    </GAHoc>
  );
};
export default Join;
