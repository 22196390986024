import { useState } from "react";
import { DropDownBtn, DropDownList } from "./Button";

const DropDown = ({ items, reqPeriod, setReqPeriod }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <DropDownBtn onClick={() => setIsOpen(!isOpen)}>
        {reqPeriod}개월 🔻
      </DropDownBtn>
      {isOpen ? (
        <ul>
          {items.map((item) => (
            <DropDownList onClick={() => setReqPeriod(item)}>
              {item}
            </DropDownList>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default DropDown;
