import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container, Divider } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import { P, SectionTitle, TextInput, VerticalBox } from "../../Components/Text";
import { LinkBtn } from "../../Components/Button";

const Meetup = () => {
  const [name, setName] = useState("");
  // const [role, setRole] = useState("말하는 이");

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="대면모임 소개" />
        <VerticalBox>
          <SectionTitle>10월 대면모임</SectionTitle>
          <P>
            {
              "10월 대면모임은 <향수> 리뷰와 멤버분들의 휴식 방법 공유로 구성되어 진행됩니다."
            }
          </P>

          <P>{"참가 인원에는 제한이 없으니 자유롭게 신청해서 함께 해주세요"}</P>

          <P>
            {
              "아직 독서모임에서 이야기를 하는 것이 부담스러운 분들의 경우 현장에서 준비된 뱃지를 패용하시면 모임을 진행하며 의견을 묻거나 질문을 하지 않을 예정입니다."
            }
          </P>

          <P>
            {
              "10월에도 다양한 분들이 참석 하셔서 말하고 듣는 각자의 방식으로 대화를 이어가면 좋겠습니다."
            }
          </P>
          <P>아래에 닉네임을 입력하고, 모임 참가 신청서를 제출해주세요 !</P>
          <Divider />
          <TextInput
            id="data2"
            key="name_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"디스코드 닉네임"}
            onChange={(e) => setName(e.target.value)}
          />
          <LinkBtn
            onClick={() => (window.location.href = `/meetup/join?name=${name}`)}
          >
            대면모임 신청서 제출
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Meetup;
