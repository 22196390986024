import React, { useState } from "react";
import GAHoc from "../GA";
import { Container, Divider } from "../Components/Layout";
import HeaderTitle from "../Components/Title";
import { Pre, SectionTitle, VerticalBox } from "../Components/Text";
import { LinkBtn } from "../Components/Button";
import route from "../route";

const BooklistComplex = () => {
  const [show, setShow] = useState(true);

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`쉘하우스의 책 둘러보기`} />
        <VerticalBox>
          <SectionTitle>책 목록 둘러보기</SectionTitle>
          <Divider />
          <LinkBtn
            onClick={() => (window.location.href = route.booklist_userlist)}
          >
            멤버들의 추천 책
          </LinkBtn>
          <LinkBtn onClick={() => (window.location.href = route.booklist_all)}>
            쉘하우스의 모든 책
          </LinkBtn>
          <Pre
            style={{
              cursor: "pointer",
              fontSize: "20px",
              marginTop: "20px",
            }}
            onClick={() => setShow(!show)}
          >
            이전 달의 추천 책
          </Pre>
          <Divider />
          {show ? (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <LinkBtn onClick={() => (window.location.href = "/booklist/may")}>
                24년 5월
              </LinkBtn>
              <LinkBtn
                onClick={() => (window.location.href = "/booklist/june")}
              >
                24년 6월
              </LinkBtn>
              <LinkBtn
                onClick={() => (window.location.href = "/booklist/july")}
              >
                24년 7월
              </LinkBtn>
              <LinkBtn onClick={() => (window.location.href = "/booklist/aug")}>
                24년 8월
              </LinkBtn>
              <LinkBtn onClick={() => (window.location.href = "/booklist/sep")}>
                24년 9월
              </LinkBtn>
            </div>
          ) : null}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default BooklistComplex;
