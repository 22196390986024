import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  white-space: normal;
  padding: 40px 15px;
  padding-bottom: 20px;
  flex-direction: column;
  min-height: 100vh;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin: 20px 0px 40px 0px;
`;
