import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import HeaderTitle from "../Components/Title";
import { Container, Divider } from "../Components/Layout";
import GAHoc from "../GA";
import { Title, VerticalBox } from "../Components/Text";
import Book from "../Components/Book";
import bookData from "../Data/book_indiv.json";

const BookIndiv = () => {
  const { name } = useParams();
  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${name} 개인 책 추천`} />
        <VerticalBox>
          <Title style={{ textAlign: "left", padding: "0 25px" }}>{name}</Title>
          <Divider style={{ height: "3px" }} />
          {bookData[name] ? (
            <>
              <Book bookInfo={bookData[name]} />
              <Divider />
            </>
          ) : (
            <Title>이번 달은 {name} 님을 위한 개인 추천이 없습니다 🐢</Title>
          )}
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default BookIndiv;
