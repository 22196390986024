import React from "react";
import styled from "styled-components";

const Base = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  height: 5px;
  background-color: #aeaeae;
`;
const Progress = styled.div.attrs(({ progress }) => ({
  style: {
    width: `${progress}%`,
  },
}))`
  position: absolute;
  background-color: #85a977;
  height: 5px;
`;

const ProgressBar = ({ progress }) => {
  return (
    <Base>
      <Progress progress={progress} />
    </Base>
  );
};
export default ProgressBar;
