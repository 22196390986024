import React from "react";
import { HorizontalBox } from "./Text";

const TurningPage = ({ children, goNext, goPrev, last, first }) => {
  return (
    <HorizontalBox>
      {first ? null : (
        <ion-icon
          name="chevron-back-outline"
          size="large"
          style={{ cursor: "pointer" }}
          onClick={() => goPrev()}
        ></ion-icon>
      )}
      {children}
      {last ? null : (
        <ion-icon
          name="chevron-forward-outline"
          size="large"
          style={{ cursor: "pointer" }}
          onClick={() => goNext()}
        ></ion-icon>
      )}
    </HorizontalBox>
  );
};
export default TurningPage;
