const route = {
  home: "/",
  // question1: "/q_p1",
  // result_nt: "/res_case1",
  // result_nf: "/res_case2",
  // result_st: "/res_case3",
  // result_sf: "/res_case4",
  // read: "/readBook",
  // shortBook: "/short-book",
  // schedule: "/schedule",
  // thanks: "/thanks/:type",
  // data: "/data/:secret",
  // who: "/who-is-boogi",
  // what: "/what-is-boogibooks",
  userlist: "/user",
  booklist_month: "/booklist/:month",
  booklist_all: "/booklist",
  booklist_complex: "/booklist/complex",
  booklist_user: "/booklist/byuser/:name",
  booklist_userlist: "/booklist/byuser",
  booklist_indiv: "/book_recomm/:name",
  booklist_each: "/book/:bookId",
  renewal: "/membership/renew",
  exit: "/exit",
  insta: "/redirect/instagram",
  meetup_intro: "/meetup",
  meetup_join: "/meetup/join",
  join: "/membership/join",
  pay: "/membership/pay",
  market: "/market",
};

export default route;
