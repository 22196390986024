import React, { useEffect, useRef, useState } from "react";
import { Container, Divider } from "../../Components/Layout";
import {
  Pre,
  TextInput,
  Info,
  HorizontalBox,
  VerticalBox,
  SectionTitle,
  P,
} from "../../Components/Text";
import HeaderTitle from "../../Components/Title";
import GAHoc from "../../GA";
import Logo from "../../Components/Logo";
import bookData from "../../Data/books.json";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ProgressBar from "../../Components/ProgressBar";
import TitleSection from "../../Components/TitleSection";
import Book from "../../Components/Book";
import TurningPage from "../../Components/TurningPage";
import { changeHandler } from "../../Components/MemberChecker";
import { expired } from "../../Components/Utils";

const StateResponder = ({
  state,
  goNext,
  goPrev,
  inputRef,
  month_kr,
  name,
  booklist,
  setProgress,
  setName,
  setDispState,
}) => {
  switch (state) {
    case 0:
      return (
        <VerticalBox>
          <TitleSection month_kr={month_kr} />
          <P>{"사용자의 닉네임을 입력하세요"}</P>
          <TextInput
            ref={inputRef}
            type="text"
            onChange={(e) => changeHandler(e, setName, setDispState, 1)}
            placeholder={"닉네임"}
          />
        </VerticalBox>
      );
    case 1:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} first>
          <VerticalBox>
            <SectionTitle>안녕하세요 {name} 님</SectionTitle>
            <Pre>{`
쉘하우스에서는 우리가 책을 통해 얻을 수 있는 것 4가지를 다루며 4개월을 보내고 있습니다. 

책을 통해 얻을 수 있는 것, 그 마지막 순서인 11월의 주제는 [간접 경험]입니다. 
`}</Pre>
          </VerticalBox>
        </TurningPage>
      );
    case 2:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} setProgress={setProgress}>
          <Pre>{`
간접 경험; 안전한 모험

새로운 경험은 반드시 투자를 필요로 합니다. 

안정적이지 않은 환경에 뛰어들어 전혀 모르는 것을 할 때는 무언가를 걸어야하기도 하고, 위험하기도 하죠. 

그런 것이 아니더라도 적어도 많은 시간과 에너지를 소모해야합니다.
          `}</Pre>
        </TurningPage>
      );
    case 3:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
책은 우리를 그런 모험의 세계로 안내합니다. 

그리고 우리에게 모험의 이야기를 전달해 그것을 체험으로 바꿔놓습니다. 

‘간접 경험’이 마지막에 자리하는 것은 그만큼 책을 읽는 여러 의미 중 큰 비중을 차지하는 이유이기 때문이죠. 
`}</Pre>
        </TurningPage>
      );
    case 4:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev}>
          <Pre>{`
이번 달의 추천 책으로 제가 꺼내든 것은 <언카피어블>입니다. 

11월의 쉘하우스는 함께 스타트업 회사 ‘스퀘어’를 창업하고 아마존과 같은 시장에서 경쟁할 것입니다. 

같은 경험을 하고 11월을 마무리하며 함께 대화를 나눌 멤버분들을 기다리고 있겠습니다.
`}</Pre>
        </TurningPage>
      );
    case 5:
      return (
        <TurningPage goNext={goNext} goPrev={goPrev} last>
          <VerticalBox>
            <SectionTitle>{month_kr} 추천 책 목록</SectionTitle>
            <Divider />
            {Object.entries(booklist).map(([k, v]) => {
              return <Book key={k} objKey={k} bookInfo={v} />;
            })}
            <Divider />
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <언카피어블> 
            
한 가지 문제를 풀면 새로운 문제가 생긴다. 해결책을 찾으면 또 새로운 문제가 딸려온다. 

문제-해결-문제의 사슬은 둘 중 한 가지 상황이 벌어질 때까지 계속된다. 문제 해결에 실패해 망하거나, 서로 연결되어 있지만 독립적인 혁신들로 모든 문제를 해결하거나. 

나는 이 혁신 과정을 ‘혁신 쌓기 전략’이라고 부른다.
`}</Pre>
            </div>
            <div
              style={{
                backgroundColor: "#c7c9cc",
                padding: "20px 15px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <Pre>{`📖 <언카피어블> 
            
문제는 아름다운 것이다. 특히 동기부여에 관한 한 더욱 그렇다. 

이유야 어떻든 어떤 문제에 깊은 관심을 기울인다면 동기는 무한히 부여될 수 있다. 

문제는 명백하다. 내가 자동차에서 생활하는 친구를 보고 깨달았듯 말이다. 

굳이 전문가가 알려주지 않아도 당신은 보는 순간 그것이 문제임을 저절로 알 수 있다. 

진짜 문제는 명백하다.
`}</Pre>
            </div>
          </VerticalBox>
        </TurningPage>
      );
    default:
      return (
        <HorizontalBox>
          <Info>안녕하세요 !</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
      );
  }
};

const BooklistNov = () => {
  const [name, setName] = useState("부기");
  const [progress, setProgress] = useState(0);
  const { month } = useParams();
  const { kr: month_kr, booklist } = bookData[month];
  const [dispState, setDispState] = useState(expired(month_kr[0]) ? 1 : 0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispState]);
  useEffect(() => {
    setProgress(dispState / 4);
  }, [dispState]);
  const inputRef = useRef();
  useEffect(() => {
    if (dispState === 0) {
      inputRef.current.focus();
    }
    if ((!expired(month_kr[0]), name === "부기" && dispState > 0)) {
      setDispState(0);
    }
  }, [name, dispState, month_kr]);

  const goNext = () => {
    setDispState(dispState + 1);
  };
  const goPrev = () => {
    setDispState(dispState - 1);
  };

  return (
    <GAHoc>
      <ProgressBar progress={progress * 100} />
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={`${month_kr} 책추천`} />
        <StateResponder
          state={dispState}
          goNext={goNext}
          goPrev={goPrev}
          inputRef={inputRef}
          setDispState={setDispState}
          setName={setName}
          month_kr={month_kr}
          name={name}
          booklist={booklist}
          setProgress={setProgress}
        />
      </Container>
    </GAHoc>
  );
};
export default BooklistNov;
