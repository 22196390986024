import React, { useEffect, useRef, useState } from "react";
import GAHoc from "../../GA";
import { Container } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import {
  P,
  PGray,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import memberData from "../../Data/member.json";
import DropDown from "../../Components/Dropdown";
import { LinkBtn } from "../../Components/Button";
import { sendPayEmail } from "../../Components/Utils";

const Renewal = () => {
  const [dispState, setDispState] = useState("name");
  const [name, setName] = useState("");
  const [reqPeriod, setReqPeriod] = useState(0);
  const [foreign, setForeign] = useState(false);
  const inputRef = useRef();
  const changeHandler = ({ target: { value } }) => {
    if (Object.keys(memberData).includes(value)) {
      setName(value);
      setDispState("check");
    }
  };
  useEffect(() => {
    if (reqPeriod > 0) {
      setDispState("pay");
    }
  }, [reqPeriod]);

  const RenewChecker = () => {
    const date = new Date();
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    const today = y + "-" + m + "-" + d;
    const expireAt = memberData[name].expireAt;
    const expired = today >= expireAt ? true : false;

    if (expired) {
      return (
        <VerticalBox>
          <SectionTitle>부기북스 멤버십</SectionTitle>
          <P>
            {`
            ${name}님은
            멤버십 갱신이 필요합니다.
            `}
          </P>
          <P>
            {`
            몇 개월을 연장할지
            선택해주세요.
            `}
          </P>
          <DropDown
            items={[1, 2, 3, 4, 5, 6, 12]}
            reqPeriod={reqPeriod}
            setReqPeriod={setReqPeriod}
          />
        </VerticalBox>
      );
    } else {
      return (
        <VerticalBox>
          <SectionTitle>부기북스 멤버십</SectionTitle>
          <P>
            {`
            ${name}님은
            이용기간이 종료되지 않았습니다.
            `}
          </P>
          <P>
            {`
            다음 갱신: ${expireAt}
            `}
          </P>
        </VerticalBox>
      );
    }
  };
  const payHandler = (platform) => {
    sendPayEmail(name, reqPeriod, platform);
  };

  const StateResponder = ({ state }) => {
    switch (state) {
      case "name":
        return (
          <VerticalBox>
            <SectionTitle>{"사용자의 닉네임을"}</SectionTitle>
            <SectionTitle>{"입력하세요"}</SectionTitle>
            <TextInput
              ref={inputRef}
              type="text"
              onChange={changeHandler}
              placeholder={"닉네임"}
            />
          </VerticalBox>
        );
      case "check":
        return <RenewChecker />;
      case "pay":
        return (
          <VerticalBox>
            <SectionTitle>이용료 결제</SectionTitle>
            <Pre>{`
            아래 버튼을 눌러서 이용료를 보내고
            ${name}님의 멤버십을 갱신하세요.
            `}</Pre>
            {foreign ? (
              <>
                <LinkBtn onClick={() => payHandler("페이팔")}>
                  재등록하기
                </LinkBtn>
                <LinkBtn onClick={() => payHandler("페이팔 구독")}>
                  정기 구독 등록하기
                </LinkBtn>
                <PGray>
                  해외 결제의 경우 편리하게 정기 결제를 등록해두실 수 있습니다.
                </PGray>
              </>
            ) : (
              <>
                <P>
                  {`
                ${reqPeriod}개월 이용료는 ${
                    reqPeriod === 12 ? 100000 : reqPeriod * 10000
                  } 원입니다.
                `}
                </P>
                <LinkBtn onClick={() => payHandler("네이버")}>
                  네이버페이로 송금
                </LinkBtn>
                <LinkBtn onClick={() => payHandler("카카오")}>
                  카카오페이로 송금
                </LinkBtn>
                <PGray>
                  간편송금 이용이 어려운 분들은 아래의 계좌번호로 직접
                  입금해주세요.
                </PGray>
                <PGray>(1002-133-822329 우리은행)</PGray>
              </>
            )}
            <PGray>재등록 확인 시 디스코드 메세지가 전송되며</PGray>
            <PGray>최대 1일 소요됩니다.</PGray>
            {foreign ? (
              <PGray
                onClick={() => setForeign(false)}
                style={{ fontSize: "10px", cursor: "pointer" }}
              >
                ✔️ 국내에 거주 중이신가요 ?
              </PGray>
            ) : (
              <PGray
                onClick={() => setForeign(true)}
                style={{ fontSize: "10px", cursor: "pointer" }}
              >
                ✔️ 해외에 거주 중이신가요 ?
              </PGray>
            )}
          </VerticalBox>
        );
      default:
        setDispState("name");
        return <VerticalBox>default</VerticalBox>;
    }
  };

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="멤버십 갱신" />
        <StateResponder state={dispState} />
      </Container>
    </GAHoc>
  );
};

export default Renewal;
