import React from "react";
import { P, Pre, VerticalBox } from "./Text";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Divider } from "./Layout";
const HiddenBook = ({ objKey, bookInfo }) => {
  const history = useHistory();
  const toBookEach = (id) => {
    history.push("/book/" + id);
  };

  return (
    <VerticalBox
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "left",
        textAlign: "center",
      }}
    >
      <Pre style={{ fontSize: 15 }}>{bookInfo.intro}</Pre>
      <P>분량: {bookInfo.page}P</P>

      <img
        src="/arrow-forward-circle-outline.svg"
        width={40}
        height={40}
        alt="forward-arrow"
        style={{
          cursor: "pointer",
          alignSelf: "end",
        }}
        onClick={() => toBookEach(objKey)}
      />
      <Divider />
    </VerticalBox>
  );
};
export default HiddenBook;
