import React, { useState } from "react";
import { HorizontalBox, Pre, VerticalBox } from "./Text";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Divider } from "./Layout";

const UserCard = ({ user }) => {
  const history = useHistory();
  const toBookEach = (name) => {
    history.push("/booklist/byuser/" + name);
  };
  const [show, setShow] = useState(false);

  return show ? (
    <VerticalBox
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "left",
        textAlign: "center",
        cursor: "pointer",
        alignSelf: "end",
      }}
      onClick={() => setShow(false)}
    >
      <Pre style={{ fontSize: 15 }}>{user.intro}</Pre>
      {user.name ? (
        <>
          {user.name === "코코" ? (
            <img src="/pic_coco.jpeg" alt="pic-coco" />
          ) : null}
          <img
            src="/arrow-forward-circle-outline.svg"
            width={40}
            height={40}
            alt="forward-arrow"
            style={{
              cursor: "pointer",
              alignSelf: "end",
            }}
            onClick={() => toBookEach(user.name)}
          />
          <Divider />
        </>
      ) : null}
    </VerticalBox>
  ) : (
    <VerticalBox>
      <HorizontalBox
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "left",
          textAlign: "center",
          cursor: "pointer",
          alignSelf: "end",
        }}
        onClick={() => setShow(true)}
      >
        <Pre style={{ fontSize: 15 }}>{user.accent}</Pre>
        {user.name ? (
          <img
            src="/arrow-forward-circle-outline.svg"
            width={40}
            height={40}
            alt="forward-arrow"
            style={{
              cursor: "pointer",
              alignSelf: "end",
            }}
            onClick={() => toBookEach(user.name)}
          />
        ) : null}
      </HorizontalBox>
      <Divider style={{ marginBottom: "0px" }} />
    </VerticalBox>
  );
};
export default UserCard;
