import route from "../route";
import memberData from "../Data/member.json";

export const changeHandler = (
  { target: { value } },
  setName,
  setDispState,
  nPage
) => {
  if (Object.keys(memberData).includes(value)) {
    setName(value);
    const curTime = new Date();
    if (memberData[value].expireAt < curTime.toISOString().split('T')[0]) {
      if (
        window.confirm(
          `${value}님의 쉘하우스 이용기간이 만료되었습니다. 재등록 페이지로 이동합니다.`
        )
      ) {
        window.location.href = route.renewal;
      }
    } else {
      setDispState(nPage);
    }
  }
};
