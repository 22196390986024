import styled from "styled-components";

export const LinkBtn = styled.button`
  font-family: inherit;
  color: #fefbfb;
  cursor: pointer;
  font-size: 22px;
  width: 100%;
  padding: 10px 0px;
  border-radius: 8px;
  border: 1px solid #fefbfb;
  background-color: #85a977;
  margin: 8px 0px;
`;
export const SelectBtn = styled.button`
  font-family: inherit;
  color: #2b2b2b;
  cursor: pointer;
  font-size: 22px;
  width: 30%;
  max-width: 350px;
  padding: 25px 10px;
  border-radius: 8px;
  border: 1.5px solid #85a977;
  background-color: #fefbfb;
  margin: 8px 0px;
`;
export const DropDownBtn = styled.div`
  text-align: center;
  font-family: inherit;
  font-size: 22px;
  width: 50%;
  padding: 10px 10px;
  cursor: pointer;
  border: 0px solid transparrent;
  border-bottom: 1.5px solid #85a977;
  margin-top: 30px;
`;
export const DropDownList = styled.li`
  text-align: center;
  font-family: inherit;
  font-size: 22px;
  width: 50%;
  padding: 10px 10px;
  cursor: pointer;
  border: 0px solid transparrent;
  border-bottom: 1.5px solid #85a977;
`;
export const FormBtn = styled.div`
  font-family: inherit;
  color: #2b2b2b;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1.5px solid #85a977;
  margin: 10px 0;
  background-color: #fefbfb;
`;
