import React from "react";
import Home from "./Screen/Home";
import GlobalStyles from "./GlobalStyles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import route from "./route";
import Notfound from "./Screen/Notfound";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import RedirectInsta from "./Screen/RedirectInsta";
import BooklistAll from "./Screen/BooklistAll";
import BookIndiv from "./Screen/BookIndiv";
import Renewal from "./Screen/membership/Renewal";
import Meetup from "./Screen/Meetup/Intro";
import MeetupJoin from "./Screen/Meetup/Join";
import BookEach from "./Screen/BookEach";
import Join from "./Screen/membership/Join";
import BooklistMonth from "./Screen/BooklistMonth";
import BooklistUser from "./Screen/BooklistUser";
import BooklistUserlist from "./Screen/BooklistUserlist";
import Pay from "./Screen/membership/Pay";
import BooklistComplex from "./Screen/BooklistComplex";
import Market from "./Screen/Market";

function App() {
  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route path={route.home} exact>
              <Home />
            </Route>
            <Route path={route.market} exact>
              <Market />
            </Route>
            <Route path={route.booklist_all} exact>
              <BooklistAll />
            </Route>
            <Route path={route.booklist_complex} exact>
              <BooklistComplex />
            </Route>
            <Route path={route.booklist_userlist} exact>
              <BooklistUserlist />
            </Route>
            <Route path={route.booklist_month} exact>
              <BooklistMonth />
            </Route>
            <Route path={route.renewal} exact>
              <Renewal />
            </Route>
            <Route path={route.booklist_user} exact>
              <BooklistUser />
            </Route>
            <Route path={route.booklist_indiv} exact>
              <BookIndiv />
            </Route>
            <Route path={route.booklist_each} exact>
              <BookEach />
            </Route>
            <Route path={route.insta} exact>
              <RedirectInsta />
            </Route>
            <Route path={route.meetup_intro} exact>
              <Meetup />
            </Route>
            <Route path={route.meetup_join} exact>
              <MeetupJoin />
            </Route>
            <Route path={route.join} exact>
              <Join />
            </Route>
            <Route path={route.pay} exact>
              <Pay />
            </Route>
            <Route>
              <Notfound />
            </Route>
          </Switch>
        </Router>
      </HelmetProvider>
    </ApolloProvider>
  );
}
export default App;
