import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import BooklistMay from "./booklists24/BooklistMay";
import BooklistJune from "./booklists24/BooklistJune";
import BooklistJuly from "./booklists24/BooklistJuly";
import BooklistAugust from "./booklists24/BooklistAugust";
import BooklistSep from "./booklists24/BooklistSep";
import BooklistOct from "./booklists24/BooklistOct";
import BooklistNov from "./booklists24/BooklistNov";

const BooklistMonth = () => {
  const { month } = useParams();

  switch (month) {
    case "may":
      return <BooklistMay />;
    case "june":
      return <BooklistJune />;
    case "july":
      return <BooklistJuly />;
    case "aug":
      return <BooklistAugust />;
    case "sep":
      return <BooklistSep />;
    case "oct":
      return <BooklistOct />;
    case "nov":
      return <BooklistNov />;
    default:
      return <></>;
  }
};
export default BooklistMonth;
