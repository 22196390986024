import React, { useState } from "react";
import GAHoc from "../GA";
import { Container } from "../Components/Layout";
import HeaderTitle from "../Components/Title";
import { P, SectionTitle, TextInput, VerticalBox } from "../Components/Text";
import { LinkBtn } from "../Components/Button";
import { sendRemindApply } from "../Components/Utils";

const Remind = () => {
  const [subject, setSubject] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [nickname, setNickname] = useState("");
  return (
    <GAHoc>
      <Container style={{ height: "100%" }}>
        <HeaderTitle title="2024 회고록" />
        <VerticalBox>
          <SectionTitle>2024 회고</SectionTitle>
          <P>
            여러분의 회고를 식탁에서 진행하려 합니다. [2024 리뷰]를 대체하는
            멤버의 쉘캐스트입니다. 인터뷰 형식을 취하며 질문은 운영진이 준비해
            회고 진행을 도와드릴 예정입니다.
          </P>
          <P>회고 진행은 평일 오후 6시 이후 또는 주말로 신청해주세요.</P>
          <TextInput
            id="nickname"
            key="nickname"
            type="text"
            placeholder="닉네임"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            onChange={(e) => setNickname(e.target.value)}
          />
          <TextInput
            id="book_title"
            key="book_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"회고 시간 (ex 12/10 17:00)"}
            onChange={(e) => setDateTime(e.target.value)}
          />
          <TextInput
            id="reason"
            key="book_reason"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder="회고하고 싶은 주제(공란 가능)"
            onChange={(e) => setSubject(e.target.value)}
          />
          <LinkBtn onClick={() => sendRemindApply(nickname, subject, dateTime)}>
            회고 신청
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default Remind;
