import React, { useState } from "react";
import GAHoc from "../GA";
import { Container } from "../Components/Layout";
import HeaderTitle from "../Components/Title";
import { P, SectionTitle, TextInput, VerticalBox } from "../Components/Text";
import { LinkBtn } from "../Components/Button";
import { sendBoogimarket } from "../Components/Utils";

const Market = () => {
  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [nickname, setNickname] = useState("");
  return (
    <GAHoc>
      <Container style={{ height: "100%" }}>
        <HeaderTitle title="부기 마켓" />
        <VerticalBox>
          <SectionTitle>여러분의 책장 속 책을 꺼내 자랑해주세요 !</SectionTitle>
          <P>
            여러분의 책장에서 잠자고 있는 책이 다른 사람들에게는 새로운 세계가
            되곤 합니다.
          </P>
          <P>
            부기 안에서 책을 건네고 다른 책을 받아 읽는 경험으로 더 넓은 세상을
            만날 수 있을 거에요.
          </P>
          <TextInput
            id="nickname"
            key="nickname"
            type="text"
            placeholder="닉네임"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            onChange={(e) => setNickname(e.target.value)}
          />
          <TextInput
            id="book_title"
            key="book_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"책 제목"}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextInput
            id="reason"
            key="book_reason"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder="추천 이유"
            onChange={(e) => setReason(e.target.value)}
          />
          <LinkBtn onClick={() => sendBoogimarket(nickname, title, reason)}>
            책 보내기
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};
export default Market;
