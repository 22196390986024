import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import bookData from "../Data/books.json";
import GAHoc from "../GA";
import { Container } from "../Components/Layout";
import HeaderTitle from "../Components/Title";
import { HorizontalBox, Info, VerticalBox } from "../Components/Text";
import Logo from "../Components/Logo";
import Book from "../Components/Book";
import { SelectBtn } from "../Components/Button";
import route from "../route";

const BookEach = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { bookId } = useParams();
  const book = bookData.june.booklist[bookId];
  const history = useHistory();
  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title={book.title + "| 부기북스"} />
        <HorizontalBox style={{ justifyContent: "flex-start" }}>
          <Info>{book.title}</Info>
          <Logo src="/charactor1.png" alt="logo" />
        </HorizontalBox>
        <VerticalBox>
          <Book bookInfo={book} key={bookId} />
        </VerticalBox>
        <SelectBtn
          style={{ width: "100%" }}
          onClick={() => history.push(route.booklist_all)}
        >
          다른 책 보기
        </SelectBtn>
      </Container>
    </GAHoc>
  );
};
export default BookEach;
