import React, { useState } from "react";
import GAHoc from "../../GA";
import { Container, Divider } from "../../Components/Layout";
import HeaderTitle from "../../Components/Title";
import {
  P,
  Pre,
  SectionTitle,
  TextInput,
  VerticalBox,
} from "../../Components/Text";
import { LinkBtn } from "../../Components/Button";

const Meetup = () => {
  const [name, setName] = useState("");
  const [book, setBook] = useState("");
  // const [role, setRole] = useState("말하는 이");

  return (
    <GAHoc>
      <Container style={{ paddingTop: "0px" }}>
        <HeaderTitle title="대면모임 소개" />
        <VerticalBox>
          <SectionTitle>부기 어워즈</SectionTitle>
          <P>
            {
              "12월 대면모임은 부기 어워즈로 진행됩니다. 부기 어워즈는 독서모임의 형태가 아닌 올 한해를 돌아보는 시간으로 구성했습니다."
            }
          </P>
          <P>
            {
              "부기 어워즈는 크게 두가지로 나누어집니다. 올 한해를 돌아보며 쉘하우스에서 진행한 최고의 이벤트와 콘텐츠를 소개하는 쉘하우스 어워즈와 각자 한 해동안 본 최고의 컨텐츠, 책을 공유하고 추천하는 컨텐츠 어워즈가 그 두개입니다."
            }
          </P>
          <P>
            {
              "11시 ~ 12시는 따로 콘텐츠가 없는 자유로운 소셜링 시간으로 구성됩니다. 참가자 분들은 12시까지 와서 참석해주시면 됩니다. 12시 부터 16시까지 진행되는 부기어워즈는 2시간씩 2타임으로 나누어 진행됩니다."
            }
          </P>
          <P>
            {
              "어워즈 진행 장소는 녹사평역 5분 거리에 위치한 스페이스 TMD입니다. 아래 링크를 통해 정확한 위치를 네이버 지도에서 확인 가능합니다."
            }
          </P>
          <Pre
            style={{ textDecorationLine: "underline", cursor: "pointer" }}
            onClick={() => (window.location.href = "https://naver.me/FRWmO4Kz")}
          >
            장소 확인하기
          </Pre>
          <P>{"참가 인원에는 제한이 없으니 자유롭게 신청해서 참석해주세요."}</P>
          <Divider />
          <TextInput
            id="data2"
            key="name_input_value"
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"디스코드 닉네임"}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            style={{
              width: "100%",
              textAlign: "start",
              padding: "10px 15px",
            }}
            type="text"
            placeholder={"올해의 책 (개인적으로 읽은 책도 가능합니다)"}
            onChange={(e) => setBook(e.target.value)}
          />
          <LinkBtn
            onClick={() =>
              (window.location.href = `/meetup/join?name=${name}&book=${book}`)
            }
          >
            대면모임 신청서 제출
          </LinkBtn>
        </VerticalBox>
      </Container>
    </GAHoc>
  );
};

export default Meetup;
